<template>
  <div class="workouts-slider-wrapper section-padding">
    <div class="container">
      <div class="workouts-slider-holder">
        <div class="workouts-slider-heading d-flex justify-content-between align-items-center">
          <p class="section-title">Exercicios</p>
          <div class="d-flex justify-content-end align-items-center">
            <router-link to="/workout-by-list" class="heading-link">Ver todos</router-link>
            <div class="workouts-slider-arrows d-none d-xl-flex align-items-center justify-content-center">
              <button type="button" class="workouts-slider-arrow workouts-slider-prev" @click="prevSlide">
                <chevronLeft/>
              </button>
              <button type="button" class="workouts-slider-arrow workouts-slider-next" @click="nextSlide">
                <chevronLeft/>
              </button>
            </div>
          </div>
        </div>
        <Slick ref="slick" :options="slickOptions" class="workouts-slider">
          <div class="workouts-slider-item" v-for="(item,index) in exercises" :key="index">
            <exerciseItem :exerciseItem="item"/>
          </div>
        </Slick>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import exerciseItem from "@/components/exercise/exercise-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

export default {
  name: "exercise-slider",
  components : {
    Slick,
    exerciseItem,
    chevronLeft
  },
  data: function () {
    return {
      exerciseSliderItems: [],
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true,
      }
    }
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
  },
  props: {
    exercises: {
      required: true,
    },
  },
}
</script>