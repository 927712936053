<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
    <path fill="#B9B9B9" d="M426.592 29.376c-.797-.56-1.895-.366-2.453.431-1.325 1.895-3.541 2.883-5.83 2.886-2.29-.003-4.504-.991-5.827-2.886-.558-.797-1.657-.99-2.453-.433-.798.559-.992 1.657-.433 2.455 2.071 2.954 5.43 4.392 8.713 4.389h.01c3.28 0 6.632-1.437 8.705-4.389.559-.797.364-1.896-.432-2.453zm.663-13.768c-2.146 0-3.885 1.738-3.885 3.885 0 2.145 1.739 3.885 3.885 3.885s3.885-1.74 3.885-3.885c0-2.147-1.74-3.885-3.885-3.885zm-14.005 3.885c0-2.147-1.74-3.885-3.885-3.885-2.146 0-3.885 1.738-3.885 3.885 0 2.145 1.74 3.885 3.885 3.885 2.146 0 3.885-1.74 3.885-3.885zm5.06 23.446c-10.842-.02-19.61-8.788-19.629-19.628.018-10.842 8.787-19.61 19.629-19.63 10.84.02 19.608 8.788 19.629 19.63-.021 10.84-8.79 19.609-19.63 19.628zm0-42.938C405.435.002 395 10.436 395 23.31c.001 12.873 10.435 23.307 23.31 23.308 12.873-.001 23.307-10.435 23.31-23.308C441.618 10.436 431.183.002 418.31 0z" transform="translate(-1068 -357) translate(673 357)"/>
  </svg>
</template>

<script>
  export default {
    name: "rating-4"
  }
</script>