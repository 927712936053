<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
    <path fill="#B9B9B9" d="M23.565 42.94c-10.842-.019-19.611-8.789-19.631-19.63.02-10.841 8.789-19.611 19.63-19.63 10.842.019 19.611 8.789 19.63 19.63-.019 10.841-8.788 19.611-19.63 19.63zm0-42.94C10.689 0 .255 10.435.255 23.31c0 12.875 10.434 23.309 23.31 23.31 12.875 0 23.308-10.435 23.31-23.31C46.875 10.435 36.44 0 23.565 0zm9.979 19.196l2.865-2.865c.264-.263.264-.69 0-.955-.264-.263-.691-.263-.955 0l-2.865 2.865-2.865-2.865c-.264-.263-.691-.263-.955 0-.263.264-.263.692 0 .955l2.865 2.865-2.865 2.866c-.263.264-.263.691 0 .954.132.133.305.198.477.198.173 0 .346-.065.478-.198l2.865-2.864 2.865 2.864c.132.133.305.198.478.198s.345-.065.477-.198c.264-.263.264-.69 0-.954l-2.865-2.866zm-17.857 0l2.865-2.865c.264-.263.264-.69 0-.955-.264-.263-.691-.263-.955 0l-2.865 2.865-2.865-2.865c-.264-.263-.691-.263-.955 0-.264.264-.264.692 0 .955l2.865 2.865-2.865 2.866c-.264.264-.264.691 0 .954.132.133.304.198.477.198s.346-.065.478-.198l2.865-2.864 2.865 2.864c.132.133.305.198.478.198s.345-.065.477-.198c.264-.263.264-.69 0-.954l-2.865-2.866zm7.798 7.59c5.434 0 9.84 4.405 9.84 9.838H13.646c0-5.433 4.405-9.838 9.838-9.838z" transform="translate(-673 -357) translate(673 357)"/>
  </svg>
</template>

<script>
  export default {
    name: "rating-1"
  }
</script>