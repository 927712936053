<template>
  <div class="box-modal-wrapper box-feedback-modal light-grey-bg">
    <div class="container">
      <div class="box-modal-header d-flex justify-content-center align-items-center">
          <button type="button" class="close-modal-btn" @click="closeFeedbackModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-logo">
            <headerLogo/>
          </div>
      </div>
      <div class="box-modal-body d-flex justify-content-between align-items-center flex-column">
        <div class="box-modal-flex d-flex justify-content-center align-items-center flex-column">
          <p class="feedback-modal-title">DEIXA O TEU FEEDBACK </p>
          <p class="feedback-modal-subtitle">Diz-nos como correu o teu treino</p>
          <div class="feedback-form-wrapper">
            <FormulateForm action="" class="feedback-form workout-complete" name="workout-complete" v-model="completeValues">
              <div class="feedback-form-head-btn d-flex justify-content-between align-items-center" @click="toggleActiveForm">
                <p>Finalizei o treino</p>
                <div class="feedback-form-head-radio"></div>
              </div>
              <div class="feedback-form-body-wrapper">
                <div class="feedback-form-body">
                  <div class="feedback-form-field">
                    <p class="feedback-form-field-label">Como foi esse treino?</p>
                    <div class="feedback-rating-wrapper d-flex justify-content-between align-items-center">
                      <div class="feedback-rating-item">
                        <label>
                          <input type="radio" name="feedback-rating" value="1">
                          <div class="feedback-rating-item-icon">
                            <rating1/>
                          </div>
                        </label>
                      </div>
                      <div class="feedback-rating-item">
                        <label>
                          <input type="radio" name="feedback-rating" value="2">
                          <div class="feedback-rating-item-icon">
                            <rating2/>
                          </div>
                        </label>
                      </div>
                      <div class="feedback-rating-item">
                        <label>
                          <input type="radio" name="feedback-rating" value="3">
                          <div class="feedback-rating-item-icon">
                            <rating3/>
                          </div>
                        </label>
                      </div>
                      <div class="feedback-rating-item">
                        <label>
                          <input type="radio" name="feedback-rating" value="4">
                          <div class="feedback-rating-item-icon">
                            <rating4/>
                          </div>
                        </label>
                      </div>
                      <div class="feedback-rating-item">
                        <label>
                          <input type="radio" name="feedback-rating" value="5">
                          <div class="feedback-rating-item-icon">
                            <rating5/>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="feedback-form-field">
                    <FormulateInput type="textarea" name="Message" label="Queres deixar um comentário?" placeholder="Deixar Mensagem" rows="4" v-model="completeMessage" />
                  </div>
                </div>
              </div>
            </FormulateForm>
            <FormulateForm action="" class="feedback-form workout-incomplete" name="workout-incomplete" v-model="incompleteValues">
              <div class="feedback-form-head-btn d-flex justify-content-between align-items-center" @click="toggleActiveForm">
                <p>Não Finalizei o treino</p>
                <div class="feedback-form-head-radio"></div>
              </div>
              <div class="feedback-form-body-wrapper">
                <div class="feedback-form-body">
                  <div class="feedback-form-field">
                    <FormulateInput type="select" name="reason" v-model="reason" validation="required" label="Qual a razão para não finalizar o treino?" :options="{1: 'Não achei interessante', 2: 'Achei demasiado difícil', 3: 'Não era o que esperava', 4: 'Não consegui terminar'}" />
                  </div>
                  <div class="feedback-form-field">
                    <FormulateInput type="textarea" name="Message" label="Queres deixar um comentário?" placeholder="Deixar Mensagem" rows="4" v-model="incompleteMessage" validation="required" />
                  </div>
                </div>
              </div>
            </FormulateForm>
          </div>
        </div>
        <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
          <p>{{ formResponseMessage }}</p>
        </div>
        <div class="box-modal-body-button-wrapper">
          <button type="button" class="generic-btn red-gradient big-text" @click="submitFeedbackForm">Enviar</button>
          <p class="box-modal-body-button-note">Obrigado por nos ajudar a melhorar a tua performance</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import headerLogo from "@/components/svg/logo-black.vue";
import closeIcon from "@/components/svg/close.vue";
import rating1 from "@/components/svg/rating-1.vue";
import rating2 from "@/components/svg/rating-2.vue";
import rating3 from "@/components/svg/rating-3.vue";
import rating4 from "@/components/svg/rating-4.vue";
import rating5 from "@/components/svg/rating-5.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
  name: "feedback-modal",
  components: {
    headerLogo,
    closeIcon,
    rating1,
    rating2,
    rating3,
    rating4,
    rating5
  },
  props: {
    productID: {
      required: true,
    },
  },
  data() {
    return {
      completeValues: {},
      incompleteValues: {},
      errors: [],
      submitted: false,
      completeMessage: '',
      incompleteMessage: '',
      reason: '',
      showMessage: false,
      formResponseMessage: '',
    };
  },
  methods: {
    toggleActiveForm: function (e) {
      let clickedForm = e.target.closest('.feedback-form');
      let elements = document.getElementsByClassName("feedback-form");

      elements.forEach(function(item,index) {
        elements[index].classList.remove('active');
      });

      if ( !clickedForm.classList.contains('active') ) {
        clickedForm.classList.add('active');
      }
    },
    closeFeedbackModal: function () {
      document.getElementsByClassName("box-feedback-modal")[0].classList.remove('open');
    },
    feedbackCompleteFormHandler: function () {
      let authKey = window.$cookies.get("authKey");
      let selectedRatingInput = document.querySelector('input[name="feedback-rating"]:checked') !== null;
      let selectedRating = '';

      if ( selectedRatingInput ) {
        selectedRating = document.querySelector('input[name="feedback-rating"]:checked').value;

        const requestBody = {
          product: this.$props.productID,
          rating: selectedRating,
          comment: this.completeMessage,
          finished: '1',
        };

        axios
            .post(process.env.VUE_APP_API_URL + 'products/evaluate', qs.stringify(requestBody),{
              headers: {
                Authorization: 'Bearer ' + authKey
              }
            })
            .then((response) => {
              if (response.data.success) {
                document.getElementsByClassName("box-feedback-modal")[0].classList.remove('open');
              }
            })
            .catch((e) => {
              if ( e.response.status > 500 ) {
                this.errors.push(e);
                this.showMessage = true;
                this.formResponseMessage = 'An error occurred.';
              } else {
                this.errors.push(e.response.data.message);
                this.showMessage = true;
                this.formResponseMessage = e.response.data.message;
              }
            });

      } else {
        this.showMessage = true;
        this.formResponseMessage = 'You have to give a rating to the workout';
      }
    },
    feedbackIncompleteFormHandler: function () {
      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        product: this.$props.productID,
        reason: this.reason,
        comment: this.incompleteMessage,
        finished: '0',
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'products/evaluate', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              document.getElementsByClassName("box-feedback-modal")[0].classList.remove('open');
            }
          })
          .catch((e) => {
            if ( e.response.status > 500 ) {
              this.errors.push(e);
              this.showMessage = true;
              this.formResponseMessage = 'An error occurred.';
            } else {
              this.errors.push(e.response.data.message);
              this.showMessage = true;
              this.formResponseMessage = e.response.data.message;
            }
          });
    },
    submitFeedbackForm: function () {
      let self = this;
      let elements = document.getElementsByClassName("feedback-form");

      elements.forEach(function(item,index) {
        if ( elements[index].classList.contains('active') ) {
          if ( elements[index].classList.contains('workout-complete') ) {
            self.feedbackCompleteFormHandler();
          } else {
            self.feedbackIncompleteFormHandler();
          }
        }
      });
    }
  },
}


</script>