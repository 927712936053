<template>
  <div class="box-modal-wrapper box-calendar-modal box-calendar-modal-workout">
    <div class="container">
      <div class="box-modal-header with-bg">
        <div class="d-flex justify-content-between align-items-center">
          <button type="button" class="close-modal-btn" @click="closeCalendarWorkoutModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-title">
            <p>Treino</p>
          </div>
          <div>
            <!--<button type="button" class="trash-modal-btn">
              <trashIcon/>
            </button>-->
          </div>
        </div>
        <div class="box-modal-heading d-flex justify-content-center align-items-center flex-column">
          <p class="box-modal-title text-black">{{ workoutItem.title }}</p>
          <div class="workout-item-tags">
            <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutItem.duration">
              <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
                <clock/>
              </div>
              <p>{{ workoutItem.duration }}</p>
            </div>
            <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutItem.itensity">
              <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
                <level/>
              </div>
              <p>{{ workoutItem.itensity }}</p>
            </div>
            <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutItem.level">
              <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
                <fire/>
              </div>
              <p>{{ workoutItem.level }}</p>
            </div>
          </div>
        </div>
      </div>
      <FormulateForm class="box-modal-body d-flex justify-content-between align-items-center flex-column" name="add-workout-date" @submit="addWorkoutDate">
        <div>
          <div class="workout-day-wrapper d-flex justify-content-between justify-content-lg-center align-items-center" v-if="selectedDate != ''">
            <p class="workout-day-label">Dia</p>
            <p class="workout-day-value">{{ selectedDate }}</p>
          </div>
          <div class="calendar-wrapper">
            <v-date-picker :attributes="calendarAttributes" v-model="calendarAttributes" :value="calendarAttributes" class="big-calendar" @dayclick='dayClicked' :min-date='new Date()'></v-date-picker>
          </div>
        </div>
        <div class="box-modal-body-button-wrapper">
          <button type="submit" class="generic-btn red-gradient big-text">Agendar</button>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import closeIcon from "@/components/svg/close.vue";
import trashIcon from "@/components/svg/trash.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "calendar-modal",
  components: {
    closeIcon,
    trashIcon,
    clock,
    level,
    fire
  },
  props: {
    workoutItem: {
      required: true,
    },
  },
  data: function () {
    return {
      errors: [],
      calendarInfo: [],
      calendarAttributes: [],
      selectedDate: '',
      rawDate: '',
    }
  },
  methods: {
    closeCalendarWorkoutModal: function () {
      document.getElementsByClassName("box-calendar-modal-workout")[0].classList.remove('open');
    },
    dayClicked(day) {
      if ( day.isDisabled == false ) {
        let clickedDate = day.id;
        let splitedDate = clickedDate.split('-');

        this.rawDate = clickedDate;
        this.selectedDate = splitedDate[2] + '/' + splitedDate[1] + '/' + splitedDate[0];
      }
    },

    addWorkoutDate() {
      const requestBody = {
        product: this.workoutItem.id,
        date: this.rawDate,
      };

      let authKey = window.$cookies.get("authKey");

      axios
          .post(process.env.VUE_APP_API_URL + 'calendar', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            this.closeCalendarWorkoutModal();
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    }
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'calendar',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.calendarInfo = response.data.model;

          let calendarRedDates = [];

          for ( let date in this.calendarInfo ) {
            let calendarDate = new Date(date);

            calendarRedDates.push(calendarDate);
          }

          this.calendarAttributes = [
            {
              dot: {
                style: {
                  backgroundColor: '#a01e1d',
                }
              },
              dates: calendarRedDates,
            }
          ]
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}


</script>