<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
    <path fill="#B9B9B9" d="M291.304 30.064h-9.986c-.974 0-1.762.79-1.762 1.763 0 .973.788 1.762 1.762 1.762h9.986c.974 0 1.76-.79 1.76-1.762 0-.973-.786-1.763-1.76-1.763zM281.25 19.492c0-2.145-1.74-3.885-3.885-3.885-2.146 0-3.885 1.74-3.885 3.885 0 2.146 1.739 3.886 3.885 3.886s3.885-1.74 3.885-3.886zm14.004-3.885c-2.145 0-3.885 1.74-3.885 3.885 0 2.146 1.74 3.886 3.885 3.886 2.146 0 3.886-1.74 3.886-3.886 0-2.145-1.74-3.885-3.886-3.885zM286.31 42.94c-10.841-.018-19.61-8.789-19.629-19.63.019-10.839 8.788-19.61 19.63-19.628 10.84.019 19.608 8.79 19.629 19.629-.021 10.84-8.79 19.61-19.63 19.629zm0-42.939C273.435.001 263.002 10.436 263 23.31c.002 12.873 10.435 23.307 23.31 23.31 12.874-.001 23.308-10.437 23.31-23.31C309.62 10.436 299.185 0 286.31 0z" transform="translate(-936 -357) translate(673 357)"/>
  </svg>
</template>

<script>
  export default {
    name: "rating-3"
  }
</script>