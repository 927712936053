<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
    <path fill="#B9B9B9" d="M550.31 38.142c5.434 0 9.839-4.405 9.839-9.839h-19.677c0 5.434 4.404 9.839 9.838 9.839zm8.945-22.535c-2.145 0-3.885 1.74-3.885 3.885 0 2.146 1.74 3.886 3.885 3.886 2.146 0 3.885-1.74 3.885-3.886 0-2.145-1.74-3.885-3.885-3.885zm-14.005 3.885c0-2.145-1.74-3.885-3.885-3.885-2.145 0-3.885 1.74-3.885 3.885 0 2.146 1.74 3.886 3.885 3.886 2.145 0 3.885-1.74 3.885-3.886zm5.06 23.447c-10.841-.019-19.61-8.79-19.629-19.63.019-10.84 8.788-19.608 19.629-19.628 10.84.02 19.608 8.789 19.63 19.629-.022 10.84-8.79 19.61-19.63 19.629zm0-42.939C537.435.001 527 10.435 527 23.31c.001 12.875 10.435 23.308 23.31 23.31 12.873-.002 23.308-10.435 23.31-23.31C573.62 10.435 563.183 0 550.31 0z" transform="translate(-1200 -357) translate(673 357)"/>
  </svg>
</template>

<script>
  export default {
    name: "rating-5"
  }
</script>