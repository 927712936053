<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
    <path fill="#B9B9B9" d="M155.31 29.055h-.01c-3.28 0-6.632 1.437-8.705 4.389-.558.797-.364 1.896.433 2.453.796.56 1.895.366 2.453-.431 1.324-1.895 3.54-2.883 5.829-2.886 2.29.003 4.505.99 5.828 2.886.557.797 1.656.99 2.453.432.797-.558.991-1.656.432-2.454-2.07-2.954-5.43-4.392-8.713-4.389zm0 13.884c-10.842-.018-19.61-8.787-19.629-19.63.018-10.839 8.787-19.608 19.629-19.628 10.84.02 19.608 8.79 19.629 19.629-.02 10.842-8.79 19.61-19.63 19.629zm0-42.939C142.435.001 132 10.437 132 23.31c.001 12.875 10.435 23.309 23.31 23.31 12.873-.001 23.307-10.435 23.31-23.31C178.618 10.437 168.183 0 155.31 0zm-5.06 19.493c0-2.146-1.739-3.885-3.885-3.885s-3.885 1.739-3.885 3.885 1.74 3.885 3.885 3.885c2.146 0 3.885-1.739 3.885-3.885zm14.005-3.885c-2.146 0-3.885 1.739-3.885 3.885s1.739 3.885 3.885 3.885 3.885-1.739 3.885-3.885-1.74-3.885-3.885-3.885z" transform="translate(-805 -357) translate(673 357)"/>
  </svg>
</template>

<script>
  export default {
    name: "rating-2"
  }
</script>