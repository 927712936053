<template>
  <div class="dashboard workout-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back" @click="$router.go(-1)">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">{{ workoutItem.title }}</p>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <button type="button" class="workout-detail-top-btn" :class="[ workoutItem.bookmarked ? 'active' : '']" v-on="workoutItem.bookmarked == true ? { click: removeBookmark } : { click: addBookmark }">
                <bookmark/>
              </button>
              <button type="button" class="workout-detail-top-btn" @click="toggleCalendarWorkoutModal">
                <calendar/>
              </button>
              <!--<button type="button" class="workout-detail-top-btn">
                <shareIcon/>
              </button>-->
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-7" v-if="workoutItem.is_premium > 0">
                <div v-if="!!workoutItem.media">
                  <div class="workout-detail-image" v-if="!!workoutItem.media.general" v-on="!!workoutItem.media.videos && this.$root.userInfo.premium_until != null ? { click: toggleVideoModal } : { click: toggleSubscribeModal }" :class="( workoutItem.media.videos ) ? 'has-video' : '' ">
                    <img :src="workoutItem.media.general[0].image" alt="">
                    <div class="workout-detail-image-content">
                      <div class="container">
                        <div class="d-flex justify-content-start align-items-end">
                          <div class="workout-detail-video-btn">
                            <playIcon v-if="this.$root.userInfo.premium_until != null" />
                            <lock class="lock" v-else />
                          </div>
                          <div class="d-lg-none">
                            <p class="workout-detail-subtitle">Treino</p>
                            <p class="workout-detail-title">{{ workoutItem.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-7" v-if="workoutItem.is_premium == 0">
                <div v-if="!!workoutItem.media">
                  <div class="workout-detail-image" v-if="!!workoutItem.media.general" v-on="!!workoutItem.media.videos ? { click: toggleVideoModal } : {}" :class = "( workoutItem.media.videos ) ? 'has-video' : '' ">
                    <img :src="workoutItem.media.general[0].image" alt="">
                    <div class="workout-detail-image-content">
                      <div class="container">
                        <div class="d-flex justify-content-start align-items-end">
                          <div class="workout-detail-video-btn">
                            <playIcon/>
                          </div>
                          <div class="d-lg-none">
                            <p class="workout-detail-subtitle">Treino</p>
                            <p class="workout-detail-title">{{ workoutItem.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="workout-detail-column-flex d-lg-flex justify-content-between flex-column">
                  <div>
                    <div class="workout-info">
                      <div class="d-flex container">
                        <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                          <div class="workout-info-item-icon d-none d-lg-block">
                            <clock/>
                          </div>
                          <p class="workout-info-item-title">{{ workoutItem.duration }}</p>
                          <p class="workout-info-item-subtitle">Duração</p>
                        </div>
                        <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                          <div class="workout-info-item-icon d-none d-lg-block">
                            <level/>
                          </div>
                          <p class="workout-info-item-title">{{ workoutItem.typeWorkout }}</p>
                          <p class="workout-info-item-subtitle">Tipo de Treino</p>
                        </div>

                        <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                          <div class="workout-info-item-icon d-none d-lg-block">
                            <fire/>
                          </div>
                          <p class="workout-info-item-title">{{ workoutItem.level }}</p>
                          <p class="workout-info-item-subtitle">Nível</p>
                        </div>
                      </div>
                    </div>
                    <div class="workout-author-info" v-if="!!workoutItem.createdBy && !!workoutItem.createdById">
                      <div class="container d-flex justify-content-between align-items-end">
                        <div>
                          <p class="workout-author-info-title">Treino Criado por</p>
                          <p class="workout-author-info-name">{{ workoutItem.createdBy }}</p>
                        </div>
                        <div>
                          <router-link :to="{ name: 'author-detail', params:{id:workoutItem.createdById} }" class="workout-author-info-link">Ver perfil</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="workout-detail-text-wrapper" v-if="workoutItem.description">
                      <div class="container">
                        <p class="workout-detail-text-title">Descrição do Treino</p>
                        <div class="workout-detail-text-description-wrapper">
                          <div class="workout-detail-text-description" v-html="workoutItem.description"></div>
                          <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                            <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                              <p>Ler Mais</p>
                              <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="!!workoutItem.media" class="d-none d-lg-block">
                    <div class="workout-detail-btn-wrapper" v-if="!!workoutItem.media.videos">
                      <button type="button" class="generic-btn red-gradient workout-detail-btn" v-if="workoutItem.is_premium > 0" v-on="!!workoutItem.media.videos && this.$root.userInfo.premium_until != null ? { click: toggleVideoModal } : { click: toggleSubscribeModal }">{{ startWorkoutBtnTxt }}</button>
                      <button type="button" class="generic-btn red-gradient workout-detail-btn" v-if="workoutItem.is_premium == 0" @click="toggleVideoModal">Iniciar Treino</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <exerciseSlider :exercises="workoutItem.related" v-if="workoutItem.related" />
    <equipmentBlock :parentItem="workoutItem" :equipment="workoutItem.equipments" v-if="Object.keys(workoutItem.equipments).length" />
    <div v-if="!!workoutItem.media">
      <div class="workout-detail-btn-wrapper d-lg-none" v-if="!!workoutItem.media.videos">
        <div class="container d-flex justify-content-center align-items-center">
          <button type="button" class="generic-btn red-gradient workout-detail-btn" v-if="workoutItem.is_premium > 0" v-on="!!workoutItem.media.videos && this.$root.userInfo.premium_until != null ? { click: toggleVideoModal } : { click: toggleSubscribeModal }">{{ startWorkoutBtnTxt }}</button>
          <button type="button" class="generic-btn red-gradient workout-detail-btn" v-if="workoutItem.is_premium == 0" @click="toggleVideoModal">Iniciar Treino</button>
        </div>
      </div>
      <videoModal :video="workoutItem.media.videos[0]" :key="videoModalkey" v-if="!!workoutItem.media.videos" :productID="workoutItem.id" />
    </div>
    <feedbackModal :productID="workoutItem.id" v-if="showFeedback"/>
    <calendarModalWorkout :workoutItem="workoutItem"/>
    <subscribeModal/>
  </div>
</template>

<script>
import exerciseSlider from "@/components/exercise/exercise-slider.vue";
import equipmentBlock from "@/components/equipment/equipment-block.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import bookmark from "@/components/svg/bookmark.vue";
import shareIcon from "@/components/svg/share-icon.vue";
import calendar from "@/components/svg/calendar.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import playIcon from "@/components/svg/play.vue";
import videoModal from "@/components/modals/video-modal.vue";
import subscribeModal from "@/components/modals/subscribe-modal.vue";
import feedbackModal from "@/components/modals/feedback-modal.vue";
import calendarModalWorkout from "@/components/modals/calendar-modal-workout.vue";
import lock from "@/components/svg/lock.vue";

import axios from 'axios';
import {EventBus} from "@/event-bus";
import qs from "qs";

export default {
  name: "workout-detail",
  data () {
    return {
      lang: this.$root.siteLang,
      errors: [],
      workoutItem: [],
      showFeedback: false,
      startWorkoutBtnTxt: '',
      videoModalkey: 0,
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.treinar_por,
    }
  },
  components : {
    exerciseSlider,
    equipmentBlock,
    chevronLeft,
    bookmark,
    shareIcon,
    calendar,
    clock,
    level,
    fire,
    chevronDown,
    playIcon,
    videoModal,
    subscribeModal,
    feedbackModal,
    calendarModalWorkout,
    lock
  },
  methods: {
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
    toggleVideoModal() {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {

        element.classList.remove("open");

        if ( this.showFeedback === true ) {
          document.getElementsByClassName("box-feedback-modal")[0].classList.add("open");
        }
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
      }
    },
    toggleCalendarWorkoutModal() {
      let element = document.getElementsByClassName("box-calendar-modal-workout")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    toggleSubscribeModal() {
      let element = document.getElementsByClassName("box-subscribe-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    addBookmark(e) {
      e.preventDefault();

      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        related_id: this.workoutItem.id,
        related_type: 0,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.workoutItem.bookmarked = true;

              let bookmark = {
                  "id": this.workoutItem.id,
                  "title": this.workoutItem.title,
                  "level": this.workoutItem.level,
                  "duration": this.workoutItem.duration,
                  "slug": this.workoutItem.slug
              };

              this.$root.workoutBookmarks.push(bookmark);
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
    removeBookmark(e) {
      e.preventDefault();

      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        bookmark: this.workoutItem.id,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              this.workoutItem.bookmarked = false;
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
  },
  mounted() {
    EventBus.$on('bus-toggleVideoModal', () => {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");

        if ( this.showFeedback === true ) {
          document.getElementsByClassName("box-feedback-modal")[0].classList.add("open");
        }
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
        this.videoModalkey++;
      }
    });
  },
  created() {
    const areaSlugId = this.$route.params.id;
    const areaSlugParts = areaSlugId.split('-');
    const idAreaItem = areaSlugParts[areaSlugParts.length - 1];

    axios
        .get(process.env.VUE_APP_API_URL + 'products/'+ idAreaItem + '?id_category=1&with_category_title=0&lang=' + this.lang)
        .then((response) => {
          this.workoutItem = response.data;

          if ( this.workoutItem.media.videos != undefined && this.$root.userInfo.premium_until != null ) {
            this.startWorkoutBtnTxt = 'Iniciar Treino';
          } else {
            this.startWorkoutBtnTxt = 'Subscrever';
          }
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});


    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'products/evaluate?lang=' + this.lang, { params: { product: idAreaItem }, headers: {
            Authorization: 'Bearer ' + authKey
          } })
        .then((response) => {
          if ( response.data.success ) {
            if ( !response.data.model.exists ){
              this.showFeedback = true
            } else {
              this.showFeedback = false
            }
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
  },
  beforeDestroy() {
    this.showFeedback = false;
  }
}
</script>